//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";

@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

html.dark {
  @import "variables-dark";

  @import "./node_modules/bootstrap/scss/mixins.scss";

  // Structure
  @import "custom/structure/general";
  @import "custom/structure/topbar";
  @import "custom/structure/page-head";
  @import "custom/structure/footer";
  @import "custom/structure/right-sidebar";
  @import "custom/structure/vertical";
  @import "custom/structure/horizontal-nav";
  @import "custom/structure/layouts";

  // Components
  @import "custom/components/waves";
  @import "custom/components/avatar";
  @import "custom/components/accordion";
  @import "custom/components/helper";
  @import "custom/components/preloader";
  @import "custom/components/forms";
  @import "custom/components/widgets";
  @import "custom/components/demos";
  @import "custom/components/print";

  // Plugins
  @import "custom/plugins/custom-scrollbar";
  @import "custom/plugins/calendar";
  @import "custom/plugins/session-timeout";
  @import "custom/plugins/round-slider";
  @import "custom/plugins/range-slider";
  @import "custom/plugins/sweatalert2";
  @import "custom/plugins/rating";
  @import "custom/plugins/toastr";
  @import "custom/plugins/parsley";
  @import "custom/plugins/select2";
  @import "custom/plugins/switch";
  @import "custom/plugins/datepicker";
  @import "custom/plugins/datatable";
  @import "custom/plugins/form-editors";
  @import "custom/plugins/form-upload";
  @import "custom/plugins/form-wizard";
  @import "custom/plugins/responsive-table";
  @import "custom/plugins/table-editable";
  @import "custom/plugins/apexcharts";
  @import "custom/plugins/flot";
  @import "custom/plugins/sparkline-chart";
  @import "custom/plugins/google-map";
  @import "custom/plugins/vector-maps";

  // Pages
  @import "custom/pages/authentication";
  @import "custom/pages/ecommerce";
  @import "custom/pages/email";
  @import "custom/pages/chat";
  @import "custom/pages/kanbanboard";
  @import "custom/pages/coming-soon";
  @import "custom/pages/timeline";
  @import "custom/pages/extras-pages";

  @each $color, $value in $theme-colors {
    .alert-#{$color} {
      @include alert-variant(rgba(theme-color-level($color, $alert-bg-level), .18), rgba(theme-color-level($color, $alert-border-level), .18), theme-color-level($color, $alert-color-level));
    }
  }

  color-scheme: dark;

}


.nav-tabs-custom .card.popup {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0px;
  pointer-events: none;
  z-index: 2000;
  padding: 1rem 1.5rem;
  opacity: 0;
  transition: 0.25s ease;
  gap: 10px;
  
  .buttons { 
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  &.show {
    transform: translate(-50%, -30px);
    opacity: 1;
    pointer-events: all;
  }

  .dropup .dropdown-menu {
    transform: translate3d(0px, 0px, 0px) !important;
    border-radius: 0.25rem;
    overflow: hidden;

    .btn {
      width: 100%;
      border-radius: 0;
    }
  } 
}
.btn .spinner-grow{
  height: 0.9rem;
  width: 0.9rem;
}
.btn.btn-sm .spinner-grow{
  height: 0.7875rem;
  width: 0.7875rem;
}